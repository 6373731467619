import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Save from './save/Save';
import Schedule from './schedule/Schedule';
import Accommodation from './accommodation/Accommodation';
import Expect from './expect/Expect';
import Wear from './wear/Wear';
import Rsvp from './rsvp/Rsvp';
import Information from './information/Information';
import GettingThere from './gettingthere/gettingthere';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Save />,
  },
  {
    path: "/schedule",
    element: <Schedule />,
  },
  {
    path: "/whattexpect",
    element: <Expect />,
  },
  {
    path: "/whattowear",
    element: <Wear />,
  },
  {
    path: "/gettingthere",
    element: <GettingThere />,
  },
  {
    path: "/accommodation",
    element: <Accommodation />,
  },
  {
    path: "/schedule",
    element: <Schedule />,
  },
  {
    path: "/rsvp",
    element: <Rsvp />,
  },
  {
    path: "/info",
    element: <Information />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
