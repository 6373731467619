import React, { useRef, useState } from 'react'
import Nav from '../nav/Nav';
import styled from 'styled-components';


import { GeneralContainer, FixedDiv, Center, Title, gold, Heading, SubmitButton, Text } from '../common/common.js';

const ColouredLabel = styled.label`
    color: ${gold};
`
const CheckboxWrap = styled.div`
    margin-bottom: 8px;
`
const TextInput = styled.input`
    width:300px;
    padding: 8px;
`
const CheckedInput = styled.input`
    margin-bottom: 16px;
`
const Select = styled.select`
    width:300px;
    padding: 8px;
    margin-top: 16px;
    margin-bottom: 16px;
`
const RsvpForm = styled.form`
    margin-left: 16px;
    margin-top: 48px;
    text-align: center;
    @media (max-width: 600px) {
        text-align: left;
    }
`

const Rsvp = () => {

    const formRef = useRef(null);
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);
    const scriptUrl = "https://script.google.com/macros/s/AKfycbwsej1tfZHFeTOUr96q4u_XtHO7snz_LOF7PtDWhwatuILrhZL-Jo5dS59z3ekUNZ13/exec";
    const handleSubmit = (e) => {
        e.preventDefault()
        if (!isValid()) {
            alert('Cannot send rsvp, please enter your name and email.')
            return;
        }
        setLoading(true)

        fetch(scriptUrl, {
            method: 'POST',
            body: new FormData(formRef.current),

        }).then(res => {
            setLoading(false);
            setSent(true);
        }).catch(err => console.log(err))
    }

    const isValid = () => {
        return name != null && name.length >= 3 && email != null && email.length >= 3 && email.includes('@');
    }

    const buttonLabel = () => {
        if (loading) {
            return 'Sending';
        }
        if (sent) {
            return 'Update & Resend';
        }
        return 'Send';
    }

    return (
        <GeneralContainer>
            <FixedDiv>
                <Center>
                    <Title>R.S.V.P</Title>
                    <Text>
We kindly request that our wedding ceremony and reception be an adult-only affair, with the exception of close family. 
We appreciate your understanding and look forward to celebrating this special day with you all.</Text>
                    <Text>If you make a mistake or want to change your choices please simply submit again with the same email.</Text>
                </Center>
                <div>
                    <RsvpForm method="post" ref={formRef} onSubmit={handleSubmit} name="google-sheet">
                        <Heading>Contact Details</Heading>
                        <CheckboxWrap>
                            <TextInput onChange={e => setName(e.target.value)}
                                type="" name="name" placeholder='Your Name *' />
                        </CheckboxWrap>
                        <CheckboxWrap>
                            <TextInput onChange={e => setEmail(e.target.value)}
                                type="email" name="email" placeholder='Your Email *' />
                        </CheckboxWrap>
                        <CheckboxWrap>
                            <TextInput type="number" name="phone" placeholder='Your Phone *' />
                        </CheckboxWrap>

                        <Heading>Attending</Heading>
                        <CheckboxWrap>
                            <CheckedInput type="checkbox" id="friday" name="friday" />
                            <ColouredLabel htmlFor="friday">Friday (Sangeet Night - Attendance is optional)</ColouredLabel>
                        </CheckboxWrap>
                        <CheckboxWrap>
                            <CheckedInput type="checkbox" id="saturday" name="saturday" defaultChecked={true} defaultValue={true} disabled />
                            <ColouredLabel htmlFor="saturday">Saturday (Main Ceremony & Evening Reception)</ColouredLabel>
                        </CheckboxWrap>
                        <CheckboxWrap>
                            <CheckedInput type="checkbox" id="sunday" name="sunday" />
                            <ColouredLabel htmlFor="sunday">Sunday (Morning BBQ - Attendance is optional)</ColouredLabel>
                        </CheckboxWrap>

                        <Heading>Dietary Preference</Heading>
                        <CheckboxWrap>
                            <ColouredLabel htmlFor="dietary">Please choose a dietary preference:&nbsp;</ColouredLabel>
                            <Select name="dietary" id="dietary">
                                <option value="none">No preference</option>
                                <option value="veggie">Vegetarian</option>
                                <option value="vegan">Vegan</option>
                                <option value="glutenfree">Gluten Free</option>
                            </Select>
                        </CheckboxWrap>
                        <CheckboxWrap>
                            <TextInput type="allergy" name="allergy" placeholder='Other allergies i.e. dairy' />
                        </CheckboxWrap>

                        <Heading>Accommodation Preference</Heading>
                        <CheckboxWrap>
                            <CheckedInput type="checkbox" id="glamping" name="glamping" />
                            <ColouredLabel htmlFor="glamping">I am interested in glamping.</ColouredLabel>
                        </CheckboxWrap>
                        <CheckboxWrap>
                            <CheckedInput type="checkbox" id="camping" name="camping" />
                            <ColouredLabel htmlFor="camping">I am interested in camping.</ColouredLabel>
                        </CheckboxWrap>
                        <CheckboxWrap>
                            <CheckedInput type="checkbox" id="other" name="other" />
                            <ColouredLabel htmlFor="other">I will sort a hotel, airbnb, or similar</ColouredLabel>
                        </CheckboxWrap>
                        <Center>
                            {!isValid() && <Text>Missing name and email!</Text>}
                            <SubmitButton type="submit" value={buttonLabel()} />
                            {loading && <div class="lds-heart"><div></div></div>}
                        </Center>
                    </RsvpForm>
                    <Center>
                        <Heading>Other Pages</Heading>
                        <Nav />
                    </Center>
                </div>
            </FixedDiv>
        </GeneralContainer>
    );
}

export default Rsvp;
