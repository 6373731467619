import styled from 'styled-components';
import pod from '../belltent.jpg';
import { GeneralContainer, FixedDiv, ColouredLink, Center, Title, Text, Left, Heading } from '../common/common.js';
import Nav, { NavLink } from '../nav/Nav';

const PodImg = styled.img`
    width: 100%;
    object-fit: contain;
    max-height: 200px;
`
function Accommodation() {
  return (
    <GeneralContainer>
      <FixedDiv>
        <Center>
          <Title>Accommodation</Title>
          <Text>There are multiple options available to guests, these include camping, glamping, air/bnb, and hotels.</Text>
          <NavLink href='/info'>Back To Index</NavLink>
          <Left>
            <Heading>Glamping</Heading>
            <Text>Our wedding venue has a camping site attached with facilities (toilets, showers).
              They have a partnership with a local glamping company and can offer guests full furnished ready on arrival glamping bell tents.
              Complete with double airbed, duvet, pillows and bedding. Furnished with cushions and lap trays, decorated with fairy lights and lanterns.
              They are available for £200 per pod for the full 3 nights. There are two remaining if folks are still interested, please message us for more information.</Text>
            <PodImg src={pod} />
            <Heading>Camping</Heading>
            <Text>You are welcome to bring your own tent, camper van, caravan etc and use the facilities.</Text>
            <Heading>Hotels</Heading>
            <Text><ColouredLink href="https://www.redlionarlingham.co.uk" target="_blank">The Red Lion</ColouredLink>, Arlingham, Call: <ColouredLink href="tel:01452 740700">01452 740700</ColouredLink></Text>
            <Text><ColouredLink href="mailto:theorchardsafari@gmail.com" target="_blank">The Orchard</ColouredLink>, Arlingham, Call: <ColouredLink href="tel:07786158479">07786 158479</ColouredLink></Text>
            <Text><ColouredLink href="https://www.thebellatframpton.co.uk" target="_blank">The Bell Inn</ColouredLink>, Arlingham, Call: <ColouredLink href="tel:01452740346">01452 740346</ColouredLink></Text>
            <Text><ColouredLink href="https://www.travelodge.co.uk/hotels/99/Stonehouse-hotel" target="_blank">Travelodge</ColouredLink>, Stonehouse, Call: <ColouredLink href="tel:08719846054">0871 984 6054</ColouredLink></Text>
            <Text><ColouredLink href="https://www.thefrocester.co.uk" target="_blank">The George</ColouredLink>, Frocester, Call: <ColouredLink href="tel:01453828683">01453 828683</ColouredLink></Text>
            <Text><ColouredLink href="https://www.stonehousecourt.co.uk" target="_blank">Stonehouse Court Hotel</ColouredLink>, Stonehouse, Call: <ColouredLink href="tel:01453794950">01453 794 950</ColouredLink></Text>
            <Heading>Airbnb</Heading>
            <Text><ColouredLink href="https://www.airbnb.co.uk/s/Arlingham--Gloucester--UK/homes?tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&flexible_trip_lengths%5B%5D=one_week&price_filter_input_type=2&price_filter_num_nights=5&channel=EXPLORE&query=Arlingham%2C%20Gloucester&place_id=ChIJ_QwcFXGmcUgRx72TPLxrVMw&date_picker_type=calendar&checkin=2024-07-05&checkout=2024-07-07&source=structured_search_input_header&search_type=autocomplete_click">Checkout airbnb for lots of local b&b's</ColouredLink></Text>
          </Left>
          <Heading>Other Pages</Heading>
          <Nav />
        </Center>
      </FixedDiv>
    </GeneralContainer>
  );
}

export default Accommodation;
