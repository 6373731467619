import saree from '../saree.jpg';
import lehenga from '../lenga.jpg';
import suit from '../suit.jpg';
import sherwani from '../sherwani.jpg';
import kurta from '../kurta.jpg';
import band from '../band.jpg';
import nehru from '../nehru.jpg';
import Nav, { NavLink } from '../nav/Nav';
import styled from 'styled-components';

import { GeneralContainer, FixedDiv, Center, Title, Text, Left, Heading, ColouredLink } from '../common/common.js';

const Outfit = styled.img`
    width: 100%;
    object-fit: contain;
    max-height: 200px;
    margin-bottom: 18px;
`

function Wear() {
    return (
        <GeneralContainer>
            <FixedDiv>
                <Center>
                    <Title>What to wear?</Title>
                    <Text>We are having an indian ceremony which means we'll both be in full indian wedding attire along with
                        the members of the wedding party. <br /><br />Everyone is free to wear whatever smart attire they prefer
                        but we'd really encourage everyone to join in the fun and wear tradditional indian wedding clothing.
                        No pressure though!
                        <br /><br />Please find below details of the options; and where to buy at the bottom of the page.
                    </Text>
                    <NavLink href='/info'>Back To Index</NavLink>
                    <Left>
                        <Heading>Saree</Heading>
                        <Text>A saree is an Indian garment that consists of a long piece of fabric (around 5-9 yards) draped over a petticoat and blouse.
                            While it is a beautiful and elegant attire, it can be difficult to self-dress if you're not experienced in it.
                            It requires a specific technique to drape the saree, which involves pleating and tucking the fabric around the
                            waist and over the shoulder in a certain manner. Whilst this is the most known indian outfit we wouldn't recommend this for beginners;
                            but that being said if you really want to there are plenty of youtube videos or people at the wedding who can help. </Text>
                        <Outfit src={saree} />
                        <Heading>Lehenga</Heading>
                        <Text>A Lehenga is a traditional Indian wedding outfit that consists of a long skirt, a blouse,
                            and a dupatta (a long scarf).
                            It's usually heavily embellished with intricate embroidery, sequins, and beadwork.
                            The skirt is typically flared and flowing, giving the wearer a regal and elegant appearance.
                            The blouse can vary in style, from a fitted, cropped top to a more modest full-sleeved design.
                            The dupatta can be styled in various ways to add to the overall aesthetic.
                            The Lehenga is a popular choice for Indian weddings and is perfect for those who want to embrace the
                            rich cultural heritage. This is the more popular option as it's easier to style and wear.</Text>
                        <Outfit src={lehenga} />
                        <Heading>Salwar Kameez (or Suit)</Heading>
                        <Text>A Salwar Kameez is a traditional Indian wedding outfit that consists of a long tunic (Kameez), loose pants (Salwar),
                            and a dupatta (a long scarf). The tunic can vary in length and design, with some featuring intricate embroidery, sequins,
                            or beadwork. The pants are usually loose-fitting and comfortable, making them perfect for dancing and other wedding activities.
                            The dupatta can be styled in different ways to complement the outfit.
                            The Salwar Kameez is a popular choice for Indian weddings as it's both comfortable and stylish,
                            and allows for easy movement while still maintaining an elegant look. 
                            Another suit variant would be an Anarkali which is long, flowy dress with a fitted top and flared bottom that is similar to a lenga.</Text>
                        <Outfit src={suit} />
                        <Heading>Sherwani</Heading>
                        <Text>A Sherwani is a traditional Indian wedding outfit for men, characterized by a long coat-like jacket with intricate embroidery and embellishments.
                            It is usually worn with fitted trousers, creating a regal and sophisticated look,
                            perfect for special occasions like weddings but it is typically one of the more expensive options.</Text>
                        <Outfit src={sherwani} />
                        <Heading>Kurta</Heading>
                        <Text>A Kurta Pyjama is a traditional Indian wedding outfit for men, consisting of a long shirt (Kurta) paired with loose-fitting pants (Pyjama).
                            It is often made from luxurious fabrics and can be adorned with intricate embroidery or embellishments,
                            creating a stylish and comfortable ensemble for formal events like weddings.</Text>
                        <Outfit src={kurta} />
                        <Heading>Bandhgala</Heading>
                        <Text>A Bandhgala is a traditional Indian wedding outfit for men, featuring a formal jacket with a high collar and buttons in the front.
                            It is typically worn with trousers and exudes an elegant and sophisticated look, making it a popular choice
                            for groomsmen and guests at Indian weddings.</Text>
                        <Outfit src={band} />
                        <Heading>Nehru Shirt/Jacket (Grandad Collar)</Heading>
                        <Text>A fusion of Indian and Western wear, such as a kurta paired with jeans
                            or a Nehru jacket worn with chinos. Probably the simplest and cheapest option 
                            as grandad collars and commonly seen in most western shops too.</Text>
                        <Outfit src={nehru} />
                        <Heading>Where to buy (suggestions)</Heading>
                        <Text>London - Green St, Eastham</Text>
                        <Text>London - Southall</Text>
                        <Text>Birmingham - Soho Rd</Text>
                        <Text>Bradford - <ColouredLink href='https://goo.gl/maps/TkAwQr4ivqMSW7QHA?coh=178571&entry=tt'>Janan (To be vetted)</ColouredLink></Text>
                        <Text>Bolton - Coming Soon</Text>
                    </Left>
                    <Nav />
                </Center>

            </FixedDiv>
        </GeneralContainer>
    );
}

export default Wear;
