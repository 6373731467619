import React from 'react';

import styled from 'styled-components';
import pattern from '../flower-henna.png';
import couple1 from '../1.jpg';
import couple2 from '../croatia.jpg';
import couple4 from '../4.jpg';

import {pink, gold, ColouredLink} from '../common/common.js';

const RsvpContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: ${pink};
`
const RsvpRow = styled.div`
    padding-top: 8px;
    display: flex;
    width: 100%;
    justify-content: space-between;
`
const RsvpContent = styled.div`
    color: ${gold};
    width: 100%;
    text-align: center;
`
const Title = styled.h1`
    font-family: 'Clicker Script';
    font-size: 62px;
    margin: 0;
    padding: 0;
    @media (max-width: 600px) {
        font-size: 42px;
    }
`
const TitleFlicker = styled.h1`
    font-family: 'Clicker Script';
    font-size: 62px;
    margin: 0;
    padding: 0;
    @media (max-width: 600px) {
        font-size: 42px;
    }
    animation: flicker 0.9s ease-in-out infinite alternate;
`
const SubTitle = styled.h1`
    font-family: 'Clicker Script';
    font-size: 32px;
    margin: 0;
    padding: 0;
    margin-top: -16px;
    margin-bottom: -16px;
`
const UsContainer = styled.div`
    margin-top: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
    display: flex;
    width: 100%;
    background: ${gold};
    max-height: 50%;
    justify-content: space-between;
`
const UsImage = styled.img`
    object-fit: scale-down;
    max-width:34%;
    max-height: 400px;
`
const PatternImageLeft = styled.img`
    height: 300px;
    object-position: bottom 0px right 90px;
    @media (max-width: 600px) {
        height: 200px;
    }
`
const PatternImageRight = styled.img`
    height: 300px;
    object-position: bottom 0px right 90px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    @media (max-width: 600px) {
        height: 200px;
    }
`
const InfoContainer = styled.div`
    padding-top:8px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 32px;
    padding-bottom: 16px;
`
const Info = styled.h3`
    font-family: 'Lato';
`
const Link = styled.a`
    color: inherit;
    display: block;
    margin-bottom: 16px;
`
const Button = styled.a`
    background: ${gold};
    color: black;
    font-weight: 900;
    font-size: 20px;
    padding: 16px;
    text-decoration: none;
    display: block;
`

const HeaderContainer = styled.div`
    margin-top: -158px;
    @media (max-width: 600px) {
        margin-top: -62px;
    }
`

function Save() {
    const [flicker, setFlicker] = React.useState(false);

    return (
        <RsvpContainer>
            <RsvpRow>
                <PatternImageLeft src={pattern} />
                <PatternImageRight src={pattern} />
            </RsvpRow>

            <RsvpContent>
                <HeaderContainer>
                {!flicker && <Title>Rachna Bhanot</Title>}
                {flicker && <TitleFlicker>Rachna Bhanot</TitleFlicker>}
                <SubTitle>&</SubTitle>
                {!flicker && <Title onClick={() => setFlicker(true)}>Jason Taylor</Title>}
                {flicker && <TitleFlicker>Jason Taylor</TitleFlicker>}
                </HeaderContainer>
                <UsContainer>
                    <UsImage src={couple4} />
                    <UsImage src={couple1} />
                    <UsImage src={couple2} />
                </UsContainer>
                <InfoContainer>
                    <Info>Please join us for our wedding celebrations on Saturday 6th July 2024 at <ColouredLink href="https://goo.gl/maps/6wzpd3kvJYseE7LH6?coh=178571&entry=tt">Milton End Farm, Arlingham, Cotswolds</ColouredLink></Info>
                    <Info>With a pre celebration on Friday 5th July.</Info>
                    <div>
                    <Button href="/info">Click For More Info</Button>
                    </div>

                </InfoContainer>

            </RsvpContent>
        </RsvpContainer>
    );
}

export default Save;
