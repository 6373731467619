import flowers from '../flowers-new.png';
import Nav from '../nav/Nav';

import { GeneralContainer, FixedDiv, FlowerImg, Center, Title, Text, Heading } from '../common/common.js';

function Expect() {
  return (
    <GeneralContainer>
      <FixedDiv>
        <Center>
          <Title>What to expect</Title>
          <Heading>Gifts</Heading>
          <Text>The most important thing to us is that you are able to celebrate with us on our wedding day. However, if you wish to give a gift, we will gratefully accept a small contribution towards our honeymoon.</Text>
          <Heading>Sangeet (Friday)</Heading>
          <Text>
A sangeet night is a lively pre-wedding celebration in Indian culture, filled with music, dance, and joyous festivities, where family and friends come together to sing, dance, and celebrate the union of the couple in anticipation of their wedding day.</Text>
          <Text>For the friday night pizza and salad will be provided including veggie, vegan, and gluten free options.</Text>
          <Heading>Henna</Heading>
          <Text>Henna will be offered to guests on the Friday night. The henna used is entirely organic.</Text>
          <Heading>Main Ceremony (Saturday)</Heading>
          <Heading>Baaraat (Groom's marraige procession)</Heading>
          <Text>The Baaraat is a vibrant procession in Indian weddings, where the groom, accompanied by all of their family and friends, arrives at the wedding venue in style, often on a decorated horse or vehicle, amidst lively music and dancing, setting the stage for the grand festivities ahead. The grooms side will start the wedding in a different location to the brides side.</Text>
          <Heading>Milni</Heading>
          <Text>Milni is a heartwarming Indian wedding tradition where the bride's and groom's families exchange garlands and embrace, symbolizing the union of two families. It's a beautiful moment of love and acceptance shared by both sides.</Text>
          <Heading>Saat Phere (The 7 vows)</Heading>
          <Text>In Hindu tradition, marriage is not just a union between two individuals, but a sacred bond that symbolizes the unity of two souls. Central to this ceremony are the 'Saptapadi' or the 'Seven Steps,' where the couple takes seven vows, each representing a promise for a fulfilling married life. These vows encompass elements of love, respect, fidelity, companionship, and mutual support.</Text>
          <Text>The main ceremony is expected to last 1.5hrs, guests should feel free to stretch their legs during this time if they wish.</Text>
          <Nav />
        </Center>
      </FixedDiv>
    </GeneralContainer>
  );
}

export default Expect;
