import styled from 'styled-components';
import flowers from '../flowers-new.png';

export var pink = '#B8006E';
export var gold = '#CAA52B';
export var green = '#8db301';

export const GeneralContainer = styled.div`
    width: 100%;
    background-color: ${pink};
    min-height: 120vh;
    height: 100%;
`
export const FixedDiv = styled.div`
    background-image: url(${flowers});
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    background-size: 1000px 700px;
    padding-top: 35vh;
    @media (max-width: 600px) {
        padding-top: 27vh;
        background-size: contain;
    }
    @media (max-width: 415px) {
        padding-top: 20vh;
        background-size: contain;
    }
`
export const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 38px;
    margin-right: 38px;
`
export const Left = styled.div`
    text-align: left;
    width: auto;
    @media (max-width: 600px) {
        width: 100%;
    }
`
export const Title = styled.h1`
    font-family: 'Clicker Script';
    font-size: 62px;
    color: ${gold};
    margin: 0;
    padding: 0;
    @media (max-width: 600px) {
        font-size: 42px;
    }
`
export const Text = styled.p`
  color: ${gold};
  max-width: 600px;
  @media (max-width: 600px) {
    max-width: 300px;
  }
`
export const Heading = styled.h3`
  color: ${gold};
`
export const HeadingU = styled.h3`
  color: ${green};
  font-size: 20px;
  text-shadow: 1px 1px black;
`
export const ColouredLink = styled.a`
    color: ${gold};
`
export const SubmitButton = styled.input`
    font-size: 18px;
    font-weight: bold;
    margin-top: 16px;
    color: inherit;
    text-decoration: none;
    background-color: ${gold};
    padding: 8px;
    width: 200px;
    display: inline-block;
    font-family: 'Lato';
    font-weight: 400;
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
`