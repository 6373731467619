import Nav, { NavLink } from '../nav/Nav';

import { GeneralContainer, FixedDiv, Center, Title, Text, Heading, HeadingU, Left, ColouredLink } from '../common/common.js';

function Schedule() {
  return (
    <GeneralContainer>
      <FixedDiv>
        <Center>
          <Title>Schedule</Title>
          <ColouredLink href="https://goo.gl/maps/6wzpd3kvJYseE7LH6?coh=178571&entry=tt">Venue</ColouredLink>
          <NavLink href='/info'>Back To Index</NavLink>
          <HeadingU>Friday 5th July 2024</HeadingU>
          <Text>Sangeet Night (Music, Food (Pizza) & Drinks) - 6pm to 10:30pm</Text>

          <HeadingU>Saturday 6th July 2024</HeadingU>
          <Text>*For the hungry, the food doesn't start coming out until 2pm.</Text>
          <Left>
            <Text>10:45 - Guests Arrive (tea, coffee, and small pastries on arrival)</Text>
            <Text>11:00 - Groom's procession (Baaraat & Milni)</Text>
            <Text>12:00 - Bride's entrance & Indian wedding ceremony</Text>
            <Text>14:00 - Drinks, food and entertainment</Text>
            <Text>17:00 - Wedding Breakfast (Sit down meal)</Text>
            <Text>19:30 - Evening Reception</Text>
            <Text>21:00 - More food for the hungry.</Text>
            <Text>23:30 - Music off and last orders</Text>
            <Text>23:59 - Taxis</Text>
          </Left>
          <Heading>Other Pages</Heading>
          <Nav />
        </Center>
      </FixedDiv>
    </GeneralContainer>
  );
}

export default Schedule;
