import styled from 'styled-components';
import { useLocation } from 'react-router-dom'

const gold = '#CAA52B';

const PlainUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 50px;
`
export const NavLink = styled.a`
    font-family: 32px;
    font-weight: bold;
    margin-top: 8px;
    color: inherit;
    text-decoration: none;
    background-color: ${gold};
    padding: 8px;
    width: 200px;
    display: inline-block;
    font-family: 'Lato';
    font-weight: 400;
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
`

function Nav() {
    const location = useLocation();

    return (
        <>
            <nav>
                <PlainUl>
                    {'/schedule' !== location.pathname && <li>
                        <NavLink href={`/schedule`}>Schedule</NavLink>
                    </li>}
                    {'/accommodation' !== location.pathname && <li>
                        <NavLink href={`/accommodation`}>Accommodation</NavLink>
                    </li>}
                    {'/whattexpect' !== location.pathname && <li>
                        <NavLink href={`/whattexpect`}>What to expect</NavLink>
                    </li>}
                    {'/whattowear' !== location.pathname && <li>
                        <NavLink href={`/whattowear`}>What to wear</NavLink>
                    </li>}
                    {'/gettingthere' !== location.pathname && <li>
                        <NavLink href={`/gettingthere`}>Getting There</NavLink>
                    </li>}
                    {'/info' !== location.pathname && <li>
                        <NavLink href={`/info`}>Index Page</NavLink>
                    </li>}
                    <li>
                        <NavLink href={`/`}>Homepage</NavLink>
                    </li>
                </PlainUl>
            </nav>
        </>
    );
}

export default Nav;
