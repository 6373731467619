import styled from 'styled-components';
import Nav from '../nav/Nav';
import couple from '../couple.png';

import { GeneralContainer, FixedDiv, FlowerImg, Center, Title } from '../common/common.js';

const CoupleImg = styled.img`
    margin-top: -40px;
    height: 200px;
`
function Information() {
    return (
        <GeneralContainer>
            <FixedDiv>
                <Center>
                    <Title>All the Details</Title>
                    <Nav />
                    <CoupleImg src={couple} />
                </Center>
            </FixedDiv>
        </GeneralContainer>
    );
}

export default Information;
