import Nav from '../nav/Nav';

import { GeneralContainer, FixedDiv, ColouredLink, Center, Title, Text, Heading } from '../common/common.js';

function GettingThere() {
    return (
        <GeneralContainer>
            <FixedDiv>
                <Center>
                    <Title>Getting There</Title>
                    <Heading>Address</Heading>
                    <Text><ColouredLink href="https://goo.gl/maps/6wzpd3kvJYseE7LH6?coh=178571&entry=tt">MILTON END WEDDING BARN, ARLINGHAM, GL2 7JH</ColouredLink></Text>
                    
                    <Text>With onsite parking.</Text>
                    <Heading>Nearest Train Stations</Heading>
                    <Text>Stroud Train Station (20 min taxi)</Text>
                    <Text>Stonehouse Train Station (20 min taxi)</Text>
                    <Text>Gloucester Train Station (30 min taxi)</Text>
                    <Heading>Local Taxi Firms</Heading>
                    <Text><ColouredLink target="_blank">Frampton Private Hire:</ColouredLink> Call: <ColouredLink href="tel:01452 757 966">01452 757 966</ColouredLink></Text>
                    <Text><ColouredLink target="_blank">Frampton Private Hire (Mobile):</ColouredLink> Call: <ColouredLink href="tel:07767 606 111">07767 606 111</ColouredLink></Text>
                    <Text><ColouredLink target="_blank">A1 Taxi Services:</ColouredLink> Call: <ColouredLink href="tel:07719 593389">07719 593389</ColouredLink></Text>
                    <Text><ColouredLink target="_blank">Eastington Taxis:</ColouredLink> Call: <ColouredLink href="tel:01453 272222">01453 272222</ColouredLink></Text>
                    <Text><ColouredLink target="_blank">Andy Cars:</ColouredLink> Call: <ColouredLink href="tel:0800 9177945">0800 9177945</ColouredLink></Text>
                    <Nav />
                </Center>
            </FixedDiv>
        </GeneralContainer>
    );
}

export default GettingThere;
